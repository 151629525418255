<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
    <div class="slide-up-transition-enter slide-up-transition-enter-active">
        <div class="cn-mobile-index-banner">
            <swiper :modules="modules" :autoplay="{ delay: 2500, disableOnInteraction: false }" class="banner-swiper">
                <swiper-slide>
                    <div class="swiper-item">
                        <div class="mobile-default-banner">
                            <div class="mobile-default-banner-title">
                                <div class="slogan-blue">好货卖全球</div>
                                <div>TikTok 全球10亿月活</div>
                                <div>链接无限商机</div>
                            </div>
                            <div class="mobile-default-banner-content">TikTok
                                Shop跨境电商提供『全托管模式』和『商家自运营模式』<br>现已开通美国、英国、沙特阿拉伯和东南亚地区<br>（新加坡、马来西亚、泰国、越南、菲律宾）<br>更多市场即将上线，正在筹备！<br>
                            </div>
                            <div class="mobile-default-banner-extra">* 数据来源：2021年TikTok对外公开数据。 *
                                请注意：10亿月活的口径是指TikTok的用户数据，这其中包含未开放TikTok Shop的国家。</div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <a class="swiper-item" rel="noopener noreferrer" href="javascript:void(0);" @click="chatClick"
                        style="display: block; width: 100%; height: 100%; background-image: url(&quot;//p16-oec-sg.ibyteimg.com/tos-alisg-i-aphluv4xwc-sg/09d169e85ff44be8af2bef0850c8fb34~tplv-aphluv4xwc-origin-image.image&quot;); background-repeat: no-repeat; background-size: contain; background-position: center center;"></a>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <!-- 如果你需要导航按钮 -->
                <!-- <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div> -->
            </swiper>
            <div class="banner-footer">
                <a href="/zh-cn/newsroom/detail/flywheel_plan" class="banner-btn">领航！东南亚跨境商家飞轮计划</a>
                <div class="banner-swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal">
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css/bundle';
export default {
    name: 'HomeWapEnter001',
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        return {
            modules: [Autoplay],
        };
    },
    data() {
        return {

        };
    },
    mounted() {
        this.$nextTick(() => {
            // 这里的代码会在DOM更新完成后执行
            console.log('组件已加载完成！');
            this.init()
        });
    },
    created() {

    },
    methods: {
        init() {

        },
        chatClick() {
            window.ssq.push('chatOpen')
        },
    }
};
</script>
<style scoped>
a {
    outline: 0;
    text-decoration: none;
    background-color: initial;
    color: #fff;
    text-decoration: none;
}

.cn-mobile-index-container .slide-up-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all .4s ease;
}

@supports (height:100dvh) {
    .cn-mobile-index-banner {
        height: 100dvh;
    }
}

.cn-mobile-index-banner {
    height: 100vh;
    position: relative;
    width: 100%;
}

.cn-mobile-index-banner .swiper {
    height: 100vh;
}

.mobile-default-banner {
    align-items: center;
    background-image: linear-gradient(180deg, transparent -45.93%, #000 78.9%), url(//lf-gs-frontend-cn.fanchenstatic.com/obj/she-op-static/she/official/web/static/image/BG.4e2e9277.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: relative;
    width: 100vw;
}

.mobile-default-banner-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    line-height: 50px;
    position: relative;
    text-align: center;
    top: -20px;
}

.slogan-blue {
    color: #25f4ee;
}

.mobile-default-banner-content {
    color: hsla(0, 0%, 100%, .8);
    font-size: 14px;
    line-height: 24px;
    margin-top: 48px;
    padding: 0 40px;
    position: relative;
    text-align: center;
    top: -20px;
}

.mobile-default-banner-extra {
    bottom: 160px;
    color: #fff;
    font-size: 10px;
    left: 50%;
    line-height: 20px;
    opacity: .6;
    padding: 0 40px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 100%;
}

.cn-mobile-index-banner .banner-footer {
    bottom: 64px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 2;
}

.cn-mobile-index-banner .banner-footer .banner-btn {
    background-color: hsla(0, 0%, 100%, .15);
    border-radius: 12px;
    color: #fff;
    display: block;
    flex-shrink: 0;
    height: 44px;
    line-height: 44px;
    margin: 0 auto;
    margin-bottom: 32px;
    overflow: hidden;
    padding: 0 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 350px;
}

.cn-mobile-index-banner .swiper-pagination-bullet {
    background-color: #d9d9d9;
    opacity: 1;
}
</style>
