<template>
    <div>
        <!-- 移动端内容 -->
        <div v-if="isMobile">
            <HomeWap />
        </div>
        <!-- PC端内容 -->
        <div v-else>
            <HomeWeb />
        </div>
    </div>
</template>
<script>
import HomeWeb from './HomeWeb.vue'
import HomeWap from './HomeWap.vue'
export default {
    name: 'HomePage',
    components: {
        HomeWeb,
        HomeWap
    },
    data() {
        return {
            isMobile: false
        };
    },
    created() {
        this.checkMobile();
    },
    methods: {
        checkMobile() {
            const userAgent = navigator.userAgent;
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
        }
    }
};
</script>
<style scoped></style>
