<template>
    <div class="slide-transition-enter slide-transition-enter-active">
        <div class="cn-mobile-index-seller-mode">
            <div class="cn-mobile-index-seller-mode-title">内容货架双驱动<br>电商消费路径全覆盖</div>
            <div class="mode-btn-group">
                <div class="mode-btn mode-btn-active" style="color: white; background: rgb(254, 44, 85);">内容场</div>
                <div class="mode-btn" style="color: black; background: rgb(37, 244, 238);">货架场</div>
            </div>
            <Swiper :modules="modules" :autoplay="{ delay: 2500, disableOnInteraction: false }" class="swiper-coverflow swiper-3d swiper-watch-progress seller-mode-swiper">
                <SwiperSlide>
                    <div class="swiper-item"><video autoplay="" loop="" playsinline=""
                            src="https://lf3-static.bytednsdoc.com/obj/eden-cn/lm-uvpahylwv-z/ljhwZthlaukjlkulzlp/official-web/v3.mp4"></video>
                        <div class="seller-mode-desc">以实时互动的直播间形式进行商品推广</div>
                    </div>
                    <div class="swiper-slide-shadow-left swiper-slide-shadow-coverflow"
                        style="opacity: 1; transition-duration: 0ms;"></div>
                    <div class="swiper-slide-shadow-right swiper-slide-shadow-coverflow"
                        style="opacity: 0; transition-duration: 0ms;"></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="swiper-item"><video autoplay="" loop="" playsinline=""
                            src="https://lf3-static.bytednsdoc.com/obj/eden-cn/lm-uvpahylwv-z/ljhwZthlaukjlkulzlp/official-web/v2.mp4"></video>
                        <div class="seller-mode-desc">短视频中挂小黄车高效促成商品转化</div>
                    </div>
                    <div class="swiper-slide-shadow-left swiper-slide-shadow-coverflow"
                        style="opacity: 0; transition-duration: 0ms;"></div>
                    <div class="swiper-slide-shadow-right swiper-slide-shadow-coverflow"
                        style="opacity: 0; transition-duration: 0ms;"></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="swiper-item"><video autoplay="" loop="" playsinline=""
                            src="https://lf3-static.bytednsdoc.com/obj/eden-cn/lm-uvpahylwv-z/ljhwZthlaukjlkulzlp/official-web/Store page.mp4"></video>
                        <div class="seller-mode-desc">在中心化的商城页面进行商品展示</div>
                    </div>
                    <div class="swiper-slide-shadow-left swiper-slide-shadow-coverflow"
                        style="opacity: 0; transition-duration: 0ms;"></div>
                    <div class="swiper-slide-shadow-right swiper-slide-shadow-coverflow"
                        style="opacity: 1; transition-duration: 0ms;"></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="swiper-item"><video autoplay="" loop="" playsinline=""
                            src="https://lf3-static.bytednsdoc.com/obj/eden-cn/lm-uvpahylwv-z/ljhwZthlaukjlkulzlp/official-web/飞书20240525-174156.mp4"></video>
                        <div class="seller-mode-desc">营销活动资源赋能引燃大促氛围</div>
                    </div>
                    <div class="swiper-slide-shadow-left swiper-slide-shadow-coverflow"
                        style="opacity: 0; transition-duration: 0ms;"></div>
                    <div class="swiper-slide-shadow-right swiper-slide-shadow-coverflow"
                        style="opacity: 2; transition-duration: 0ms;"></div>
                </SwiperSlide>
            </Swiper>
            <div class="seller-mode-swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal">
                <span class="swiper-pagination-bullet"></span>
                <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                <span class="swiper-pagination-bullet"></span>
                <span class="swiper-pagination-bullet"></span>
            </div>
        </div>
    </div>
</template>
<script>
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css/bundle';
export default {
    name: 'HomeWapEnter002',
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        return {
            modules: [Autoplay],
        };
    },
    data() {
        return {
            swiperOption: {
                autoplay: {
                    delay: 1500,
                    disableOnInteraction: false
                }
            }
        };
    },
    created() {

    },
    methods: {

    }
};
</script>
<style scoped>
a {
    outline: 0;
    text-decoration: none;
    background-color: initial;
    color: #fff;
    text-decoration: none;
}

.cn-mobile-index-container .slide-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all .4s ease;
}

@supports (height:100dvh) {
    .cn-mobile-index-seller-mode {
        height: 100dvh;
    }
}

.cn-mobile-index-seller-mode {
    height: 100vh;
    width: 100%;
}

.cn-mobile-index-seller-mode-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    padding-top: 94px;
    text-align: center;
    text-shadow: 20px 4px 60px rgba(0, 0, 0, .4);
}

.cn-mobile-index-seller-mode .mode-btn-group {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 37px;
    margin-bottom: 50px;
}

.cn-mobile-index-seller-mode .mode-btn-group .mode-btn {
    align-items: center;
    border-radius: 12px;
    color: #000;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    height: 44px;
    justify-content: center;
    line-height: 16px;
    position: relative;
    text-align: center;
    transition: all .3s ease;
    width: 100px;
}

.cn-mobile-index-seller-mode .swiper-slide {
    background-position: 50%;
    background-size: cover;
    height: 450px;
    width: 214px;
}

.cn-mobile-index-seller-mode .swiper-slide video {
    border-radius: 24px;
    height: 380px;
    pointer-events: none;
    width: 214px;
}

.cn-mobile-index-seller-mode .seller-mode-swiper-pagination {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: center;
}

.cn-mobile-index-seller-mode .swiper-pagination-bullet {
    background-color: #d9d9d9;
    opacity: 1;
}

</style>
